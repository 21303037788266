import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import slugify from 'slugify'

import { BULLET_ICON } from '../constants'
import mq from '../styles/breakpoints'

import { BlogAuthor } from '../components/BlogAuthor'
import { Link } from '../components/Link'
import RichText from '../components/RichText'
import Section from '../components/Section'
import { TableOfContents } from '../components/TableOfContents'
import { Heading, Paragraph } from '../components/typography'
import { TableOfContentsProvider } from '../context/TableOfContentsProvider'
import { useSEOFunctions } from '../context/useSEO'
import { IImage } from '../graphql/modules/image'
import { IBlog } from '../graphql/sections/blog'
import { useReadTime } from '../helpers/richTextHelpers'
import { useSendLinkClickedEvent } from '../hooks/analytics/useSendLinkClickedEvent'

interface ISocialLink {
  image: IImage
  link: string
  title: string
}

interface ISocialImageData {
  externalLinkImage: IImage
  facebookImage: IImage
  twitterImage: IImage
  linkedinImage: IImage
}

type SocialImageKeys = 'externalLinkImage' | 'twitterImage' | 'linkedinImage' | 'facebookImage'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const BlogPostContent = styled.div<{ shouldShowTableOfContents: boolean }>(({ shouldShowTableOfContents }) =>
  mq({
    width: ['100%', '100%', '680px'],
    margin: ['auto', 'auto', shouldShowTableOfContents ? '0 0 0 auto' : 'auto'],
  }),
)

const TableOfContentsDesktop = styled.div<{ scrollPos: number | null }>(({ scrollPos }) =>
  mq({
    display: ['none', '', 'flex'],
    flexDirection: 'column',
    opacity: scrollPos ? 1 : 0,
    paddingTop: `${scrollPos ? scrollPos : 0}px`,
    position: 'relative',
    maxWidth: '338px',
    minWidth: '230px',
    flex: 1,
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    marginLeft: '50px',
  }),
)

const TableOfContentsMobile = styled.div`
  ${mq({
    display: ['flex', '', 'none'],
    width: '100%',
    marginTop: ['11px', '32px', '0'],
  })}
`

const BlogPostHeader = styled.div`
  ${mq({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: ['10px', 0, 0],
    maxWidth: ['', '', '870px'],
    margin: '0 auto',
  })}
`

const HeaderMetadata = styled.div`
  ${mq({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 20px 0',
    padding: ['0', '0', '0'],
  })}
`

const AuthorSection = styled.div`
  display: flex;
  flex-direction: column;
`

const BlogImageHeader = styled(GatsbyImage)`
  ${mq({
    width: '100%',
    height: 'auto',
    borderRadius: ['', '8px', '8px'],
  })}
`

const BlogContent = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: ['20px 0', '20px 0', '30px 0 0 0'],
    maxWidth: ['', '', '680px'],
    margin: '0 auto',
  })}
`

const BulletContainer = styled.span`
  margin: auto 4px;
  font-size: 14px;
`

const SocialButtonsSection = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    padding: ['19px 0 10px 0', '', '0'],
    justifyContent: 'space-around',
  })}
`

const SocialButton = styled.a`
  ${mq({
    padding: '0 14px',
  })}
`

const SocialImage = styled(GatsbyImage)`
  ${mq({
    width: '25px',
    height: '25px',
    margin: '0',
  })}
`

const customH1Styles = {
  padding: ['0', '0', '0'],
  margin: ['', '0px 0 20px 0', '0px 0 20px 0'],
  textAlign: ['left', 'left', 'left'],
  maxWidth: ['', '', '855px'],
  fontSize: ['30px', '', '40px'],
}

const customRichTextHeadingsStyles = {
  textAlign: 'left',
  width: '100%',
}

const customH3Styles = {
  marginBottom: ['', '15px', ''],
}

const customH4Styles = {
  marginBottom: ['5px', '10px', ''],
}

const customRichTextStyles = {
  paragraph: {
    textAlign: 'left',
    width: '100%',
    margin: ['', '', '12px 0 25px 0'],
    maxWidth: undefined,
  },
  'heading-1': customRichTextHeadingsStyles,
  'heading-2': customRichTextHeadingsStyles,
  'heading-3': { ...customRichTextHeadingsStyles, ...customH3Styles },
  'heading-4': { ...customRichTextHeadingsStyles, ...customH4Styles },
  'heading-5': customRichTextHeadingsStyles,
  'unordered-list': {
    padding: ['0 0 30px 0px'],
  },
}

const customMetadataParagraphStyles = {
  textAlign: 'left',
  margin: 0,
}

const customAuthorParagraphStyles = {
  ...customMetadataParagraphStyles,
  fontWeight: 'bold',
}

const customBlogSection = {
  padding: ['0px 22px', '', '35px 50px'],
}

const useElementOffsetTop = () => {
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null)
  const [scrollPos, setScrollPos] = useState<number | null>(null)
  const isEventHandlerEnabled = useRef(false)

  useEffect(() => {
    const doOffsetCalc = () => {
      if (!elementRef) return
      let elementTop = elementRef.offsetTop
      if (elementRef.parentElement) elementTop -= elementRef.parentElement.offsetTop
      if (scrollPos !== elementTop) return setScrollPos(elementTop)
    }
    let interval: ReturnType<typeof setInterval> | void

    if (!isEventHandlerEnabled.current) {
      interval = setInterval(() => {
        doOffsetCalc()
      }, 10)

      setTimeout(() => {
        if (interval) interval = clearInterval(interval)
        isEventHandlerEnabled.current = true
        window.addEventListener('resize', doOffsetCalc)
      }, 2000)
    }
    return () => {
      if (interval) clearInterval(interval)
      if (isEventHandlerEnabled.current) window.removeEventListener('resize', doOffsetCalc)
    }
  }, [elementRef, scrollPos])

  return { setElementRef, scrollPos }
}

export const BlogPostSection: React.FC<{ data: IBlog }> = ({ data }) => {
  const {
    title,
    image: {
      image: { gatsbyImageData },
    },
    authorBio,
    link: { link },
    date,
  } = data

  const seoFunctions = useSEOFunctions()

  useEffect(() => {
    typeof seoFunctions === 'function' &&
      seoFunctions({
        type: 'articleData',
        data: {
          headline: title,
          image: gatsbyImageData.images.fallback?.src ?? '',
          datePublished: new Date(date).toISOString(),
          author: {
            name: authorBio.title,
            url: authorBio.website,
            jobTitle: authorBio.jobTitle,
            description: authorBio.bio.bio,
          },
        },
      })
  }, [title, date, seoFunctions, gatsbyImageData, authorBio])

  const { externalLinkImage, facebookImage, twitterImage, linkedinImage }: ISocialImageData = useStaticQuery(graphql`
    query SocialImageQuery {
      externalLinkImage: contentfulImage(contentful_id: { eq: "2SvLbD8vGU9ulz8eFjBkWS" }) {
        ...ImageFragment
      }
      facebookImage: contentfulImage(contentful_id: { eq: "7hWJuYjkvU5L07fqnYAX3p" }) {
        ...ImageFragment
      }
      twitterImage: contentfulImage(contentful_id: { eq: "31cdgcaudJ4j1dJvB4OfWH" }) {
        ...ImageFragment
      }
      linkedinImage: contentfulImage(contentful_id: { eq: "1BGO5qyHg7fhbvPkH3SPLs" }) {
        ...ImageFragment
      }
    }
  `)

  const socialLinks: ISocialLink[] = [
    {
      title: 'Facebook',
      image: facebookImage,
      link: `https://www.facebook.com/sharer.php?u=https://borrowell.com${link}`,
    },
    {
      title: 'Twitter',
      image: twitterImage,
      link: `https://twitter.com/intent/tweet?url=https://borrowell.com${link}&text=${encodeURIComponent(
        title,
      )}&via=borrowell`,
    },
    {
      title: 'Linkedin',
      image: linkedinImage,
      link: `http://www.linkedin.com/shareArticle?mini=true&url=https://borrowell.com${link}`,
    },
  ]

  const socialImages: Record<SocialImageKeys, IImage> = {
    externalLinkImage: externalLinkImage,
    facebookImage: facebookImage,
    twitterImage: twitterImage,
    linkedinImage: linkedinImage,
  }

  return <BlogPost data={data} socialImages={socialImages} socialLinks={socialLinks} />
}

export const BlogPost: React.FC<{
  data: IBlog
  socialLinks: ISocialLink[]
  socialImages: Record<string, IImage>
}> = ({ data, socialLinks, socialImages }) => {
  const {
    title,
    image: {
      image: { gatsbyImageData },
      alternateText: { alternateText: altText },
    },
    authorBio,
    date,
    blogText,
    shouldShowTableOfContents,
    contentful_id,
    __typename,
  } = data

  const readTime = useReadTime(blogText)
  const seoFunctions = useSEOFunctions()

  const { setElementRef, scrollPos } = useElementOffsetTop()

  const sendLinkClickedEvent = useSendLinkClickedEvent()

  const handleClick = (contentfulId: any, typeName: any, platformLink: any, altText: any) => {
    sendLinkClickedEvent({
      entry_id: contentfulId,
      contentful_type: typeName,
      link_to: platformLink,
      link_text: altText ?? 'N/A',
    })
  }

  useEffect(() => {
    typeof seoFunctions === 'function' &&
      seoFunctions({
        type: 'articleData',
        data: {
          headline: title,
          image: gatsbyImageData.images.fallback?.src ?? '',
          datePublished: new Date(date).toISOString(),
          author: {
            name: authorBio.title,
            url: authorBio.website,
            jobTitle: authorBio.jobTitle,
            description: authorBio.bio.bio,
          },
        },
      })
  }, [title, date, seoFunctions, gatsbyImageData, authorBio])

  return (
    <Section title={title} customStyles={customBlogSection}>
      <TableOfContentsProvider>
        <Wrapper>
          <BlogPostContent shouldShowTableOfContents={!!shouldShowTableOfContents}>
            <BlogPostHeader>
              <Heading styleOverrides={customH1Styles}>{title}</Heading>
              <HeaderMetadata>
                <AuthorSection>
                  <Link
                    to={`/blog/author/${slugify(authorBio?.title.toLowerCase() ?? '/')}`}
                    styleOverrides={customAuthorParagraphStyles}
                    contentfulId={authorBio.contentful_id}
                    typeName={__typename}
                  >
                    {authorBio?.title}
                  </Link>
                  <Paragraph styleOverrides={customMetadataParagraphStyles}>
                    <>
                      {date} <BulletContainer>{BULLET_ICON}</BulletContainer> {readTime} min read
                    </>
                  </Paragraph>
                </AuthorSection>

                {socialLinks?.length > 0 && (
                  <SocialButtonsSection>
                    {socialLinks.map(
                      ({
                        image: {
                          image: { gatsbyImageData },
                          alternateText: { alternateText: altText },
                          contentful_id: contentfulId,
                          __typename: typeName,
                        },
                        title,
                        link: platformLink,
                      }) => {
                        return (
                          <SocialButton
                            href={platformLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={title}
                            data-entry-id={contentfulId}
                            onClick={() => handleClick(contentfulId, typeName, platformLink, altText)}
                          >
                            <SocialImage image={gatsbyImageData} alt={altText} />
                          </SocialButton>
                        )
                      },
                    )}
                  </SocialButtonsSection>
                )}
              </HeaderMetadata>
              {/* div ref is for detecting top of element for matching table of contents */}
              <div ref={newRef => setElementRef(newRef)}>
                <BlogImageHeader image={gatsbyImageData} alt={altText} />
              </div>
              {shouldShowTableOfContents && (
                <TableOfContentsMobile>
                  <TableOfContents isMobile />
                </TableOfContentsMobile>
              )}
            </BlogPostHeader>
            <BlogContent>
              <RichText content={blogText} customStyles={customRichTextStyles} />
            </BlogContent>
            {authorBio && <BlogAuthor data={authorBio} socialImages={socialImages} />}
          </BlogPostContent>

          {shouldShowTableOfContents && (
            <TableOfContentsDesktop scrollPos={scrollPos}>
              <TableOfContents />
            </TableOfContentsDesktop>
          )}
        </Wrapper>
      </TableOfContentsProvider>
    </Section>
  )
}
